* {
    box-sizing: border-box;
}

:root {
    --background: #ffffff;
    --text: #333333;
    --panel: #f2f2f2;
    --header: #f0f0f0;
    --footer: #cccccc;
    --active-menu-item-background: #cccccc;
    --active-menu-item-text: #333333;
    --red-background: #fbb;
    --blue-background: #bbf;
    --green-background: #bfb;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #222;
        --text: #fff;
        --panel: #444;
        --header: #333333;
        --footer: #666666;
        --active-menu-item-background: #222;
        --active-menu-item-text: #fff;
        --red-background: #f005;
        --blue-background: #00f5;
        --green-background: #0f05;
    }
}

html,
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0px;
    background-color: var(--background);
    color: var(--text);
    height: 100vh;
    user-select: none;
    -webkit-user-select: none;

    display: flex;
    flex-direction: column;
    justify-items: center;
}

main {
    /* anchor chat header */
    position: relative;
    flex: 1;
    padding: 10px;
    padding-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    overflow-wrap: break-word;
    word-break: break-word;
}

a {
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    outline: none;
}

button {
    color: var(--text);
    background-color: var(--panel);
    padding: 5px 10px;
    border-radius: 20px;
}

button:hover {
    background-color: var(--active-menu-item-background);
    color: var(--active-menu-item-text);
}

form {
    width: 80%;
}

h1 {
    /* color: inherit; */
}

p.center {
    text-align: center;
}

/*///////////////////////////////////////////////////////////////////////////*/

header {
    background-color: var(--header);
    color: var(--text);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
}

header>div {
    /* padding: 10px; */
    height: 100%;
}

header a {
    text-decoration: none;
}

.top-menu-bar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* padding-left: 5px; */
}

.top-menu {
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.top-menu.active {
    background-color: var(--active-menu-item-background);
    color: var(--active-menu-item-text);
}

.top-menu.not-active {
    background-color: green;
}

#username {
    /* font-size: 14px; */
    /* background-color: var(--active-menu-item-background);
    color: var(--active-menu-item-text); */

    cursor: pointer;
    padding: 10px;
}

#username>* {
    pointer-events: none;
}

.dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    background-color: var(--panel);
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-menu a {
    background-color: var(--background);
    color: var(--active-menu-item-text);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-menu a:hover {
    background-color: var(--active-menu-item-background);
    color: var(--active-menu-item-text);
}

.show {
    display: block;
}

#bad-request-message {
    font-weight: bold;
}

/*///////////////////////////////////////////////////////////////////////////*/

footer {
    background-color: var(--active-menu-item-background);
    color: var(--text);
    width: 100%;
    padding: 10px;
    flex-basis: content;
}

footer:empty {
    display: none;
}

footer>div {}

.error {
    background-color: red;
}

/*///////////////////////////////////////////////////////////////////////////*/

.connections-container {
    width: 100%;
}

.connections {
    width: 100%;
}

.inbound.connections {
    padding: 5px;
    background-color: var(--red-background);
    border-radius: 10px;
}

.outbound.connections {
    padding: 5px;
    background-color: var(--blue-background);
    border-radius: 10px;
}

.connected.connections {
    padding: 5px;
    background-color: var(--green-background);
    border-radius: 10px;
}

.connections:empty {
    display: none;
}

/*///////////////////////////////////////////////////////////////////////////*/

.page-column,
.form-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.page-column>* {
    margin: 0;
}

.menu-list {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.menu-list .panel,
.menu-list div {
    text-align: center;
}

.panel {
    user-select: text;
    padding: 15px;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--panel);
    color: var(--text);
    display: grid;
    gap: 10px;
}

.menu-list button {
    padding: 15px 10px;
}

/*///////////////////////////////////////////////////////////////////////////*/

.user-panel {
    width: 100%;
    user-select: text;
    padding: 15px;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--panel);
    color: var(--text);
    display: grid;
    gap: 5px;
}

.user-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-bio {
    white-space: pre-line;
}

.user-panel:first-child {
    margin-top: 0px;
}

.user-panel:not(:first-child) {
    margin-top: 25px;
}

.user-panel:last-child {
    margin-bottom: 5px;
}

.user-panel:not(:last-child) {
    margin-bottom: 25px;
}

.connection-note {
    font-style: italic;
}

.button-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
}

.button-bar>div {
    flex-grow: 1
}

button.set {
    background-color: var(--background);
    color: var(--text)
}

button.break {
    border: 1px solid red;
}

/*///////////////////////////////////////////////////////////////////////////*/

.canvas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    color: black;
}

canvas {
    max-width: 100%;
    max-height: 100%;
}

.qr-link {
    margin-top: 10px;
    font-size: 14px;
}

/*///////////////////////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////////// OVERLAY */
/*///////////////////////////////////////////////////////////////////////////*/

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.1);
}

.modal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal>* {
    background-color: var(--panel);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    padding: 20px;
    /* border: 5px solid #888; */
    width: 80%;
    height: 80%;
    max-width: 400px;
    max-height: 300px;
    overflow: auto;
    box-sizing: border-box;
}

.modal>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.modal>form textarea {
    background-color: inherit;
    color: inherit;
    width: 80%;
}

.modal>form h2 {
    margin: 0px;
}

.modal .close {
    align-self: right;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.modal .close:hover,
.modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/*///////////////////////////////////////////////////////////////////////////*/

.interests-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.interests-page h1 {
    margin-bottom: 0px;
}

.interests-list {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 10px;
}

.interests-list * {
    margin: 2px;
}

.interests-list h2 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.interests-list .category {
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: larger;
}

/*///////////////////////////////////////////////////////////////////////////*/
/*///////////////////////////////////////////////////////////////// MESSAGES */

.all-message-list {
    display: grid;
    grid-template-columns: 1fr min-content 4fr;
    grid-auto-flow: row;
    align-items: baseline;
    column-gap: 10px;
    row-gap: 5px;
}

.message-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: baseline;
    column-gap: 10px;
    row-gap: 0px;
}

/* .message-list>div:nth-child(3n) {
    grid-column: 1 / -1;
} */

.message {
    border: 1px solid var(--text);
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.message-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3em;
}

.timestamp {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
}

.date,
.time,
.other-user-name {
    word-break: keep-all;
    text-wrap: nowrap;
    overflow-wrap: normal;
}

.text {
    white-space: pre-line;
    overflow-wrap: break-word;
    word-break: break-word;
}

.chat-header {
    /* position: fixed; */
    width: 100%;
    background-color: var(--active-menu-item-background);
    /* undo main padding */
    /* margin-left: -10px; */
    /* cover the tiny gap between the header and this */
    /* margin-top: -1px; */
    padding: 10px;
    /* top: 0px; */
    font-size: larger;
    font-weight: bold;
    display: flex;
    gap: 10px;
}

.chat-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.chat-time {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
    font-size: small;
}

.chat-in,
.chat-out {
    border-radius: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 5px;
    max-width: 80%;
    white-space: pre-line;
    overflow-wrap: break-word;
}

.chat-in {
    background-color: var(--panel);
    align-self: flex-start;
}

.chat-out {
    align-self: flex-end;
    background-color: var(--blue-background);
}

.chat-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.chat-input textarea {
    width: 100%;

    /* flex-grow: 1;
    resize: vertical; */
    height: auto;
    font: inherit;
}

.center {
    text-align: center;
}

.medium {
    max-width: 800px;
}

textarea.bio {
    width: 100%;
    height: auto;
    font: inherit;
}

/*///////////////////////////////////////////////////////////////////////////*/

.top-margin-0 {
    margin-top: 0;
}