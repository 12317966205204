* {
  box-sizing: border-box;
}

:root {
  --background: #fff;
  --text: #333;
  --panel: #f2f2f2;
  --header: #f0f0f0;
  --footer: #ccc;
  --active-menu-item-background: #ccc;
  --active-menu-item-text: #333;
  --red-background: #fbb;
  --blue-background: #bbf;
  --green-background: #bfb;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #222;
    --text: #fff;
    --panel: #444;
    --header: #333;
    --footer: #666;
    --active-menu-item-background: #222;
    --active-menu-item-text: #fff;
    --red-background: #f005;
    --blue-background: #00f5;
    --green-background: #0f05;
  }
}

html, body {
  background-color: var(--background);
  color: var(--text);
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
}

main {
  overflow-wrap: break-word;
  word-break: break-word;
  flex: 1;
  padding: 0 10px 10px;
  position: relative;
  overflow: hidden auto;
}

a {
  background-color: inherit;
  color: inherit;
  outline: none;
  text-decoration: none;
}

button {
  color: var(--text);
  background-color: var(--panel);
  border-radius: 20px;
  padding: 5px 10px;
}

button:hover {
  background-color: var(--active-menu-item-background);
  color: var(--active-menu-item-text);
}

form {
  width: 80%;
}

p.center {
  text-align: center;
}

header {
  background-color: var(--header);
  color: var(--text);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 30px;
  display: flex;
  position: relative;
}

header > div {
  height: 100%;
}

header a {
  text-decoration: none;
}

.top-menu-bar {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.top-menu {
  padding: 10px 5px;
}

.top-menu.active {
  background-color: var(--active-menu-item-background);
  color: var(--active-menu-item-text);
}

.top-menu.not-active {
  background-color: green;
}

#username {
  cursor: pointer;
  padding: 10px;
}

#username > * {
  pointer-events: none;
}

.dropdown-menu {
  background-color: var(--panel);
  z-index: 1;
  min-width: 120px;
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-menu a {
  background-color: var(--background);
  color: var(--active-menu-item-text);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: var(--active-menu-item-background);
  color: var(--active-menu-item-text);
}

.show {
  display: block;
}

#bad-request-message {
  font-weight: bold;
}

footer {
  background-color: var(--active-menu-item-background);
  color: var(--text);
  flex-basis: content;
  width: 100%;
  padding: 10px;
}

footer:empty {
  display: none;
}

.error {
  background-color: red;
}

.connections-container, .connections {
  width: 100%;
}

.inbound.connections {
  background-color: var(--red-background);
  border-radius: 10px;
  padding: 5px;
}

.outbound.connections {
  background-color: var(--blue-background);
  border-radius: 10px;
  padding: 5px;
}

.connected.connections {
  background-color: var(--green-background);
  border-radius: 10px;
  padding: 5px;
}

.connections:empty {
  display: none;
}

.page-column, .form-column {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.page-column > * {
  margin: 0;
}

.menu-list {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 300px;
  display: flex;
}

.menu-list .panel, .menu-list div {
  text-align: center;
}

.panel {
  user-select: text;
  background-color: var(--panel);
  color: var(--text);
  border: 1px solid gray;
  border-radius: 10px;
  gap: 10px;
  padding: 15px;
  display: grid;
  box-shadow: 0 0 10px #0000004d;
}

.menu-list button {
  padding: 15px 10px;
}

.user-panel {
  user-select: text;
  background-color: var(--panel);
  color: var(--text);
  border: 1px solid gray;
  border-radius: 10px;
  gap: 5px;
  width: 100%;
  padding: 15px;
  display: grid;
  box-shadow: 0 0 10px #0000004d;
}

.user-top-bar {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.user-bio {
  white-space: pre-line;
}

.user-panel:first-child {
  margin-top: 0;
}

.user-panel:not(:first-child) {
  margin-top: 25px;
}

.user-panel:last-child {
  margin-bottom: 5px;
}

.user-panel:not(:last-child) {
  margin-bottom: 25px;
}

.connection-note {
  font-style: italic;
}

.button-bar {
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
  display: flex;
}

.button-bar > div {
  flex-grow: 1;
}

button.set {
  background-color: var(--background);
  color: var(--text);
}

button.break {
  border: 1px solid red;
}

.canvas-container {
  color: #000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

canvas {
  max-width: 100%;
  max-height: 100%;
}

.qr-link {
  margin-top: 10px;
  font-size: 14px;
}

.modal {
  z-index: 1;
  background-color: #0000001a;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal.active {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal > * {
  background-color: var(--panel);
  box-sizing: border-box;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  height: 80%;
  max-height: 300px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 0 10px #000c;
}

.modal > form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
}

.modal > form textarea {
  background-color: inherit;
  color: inherit;
  width: 80%;
}

.modal > form h2 {
  margin: 0;
}

.modal .close {
  align-self: right;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal .close:hover, .modal .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.interests-page {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.interests-page h1 {
  margin-bottom: 0;
}

.interests-list {
  grid-template-columns: min-content 1fr;
  align-items: center;
  column-gap: 10px;
  display: grid;
}

.interests-list * {
  margin: 2px;
}

.interests-list h2 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.interests-list .category {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: larger;
  font-weight: bold;
}

.all-message-list {
  grid-template-columns: 1fr min-content 4fr;
  grid-auto-flow: row;
  align-items: baseline;
  gap: 5px 10px;
  display: grid;
}

.message-list {
  grid-template-columns: repeat(1, 1fr);
  align-items: baseline;
  gap: 0 10px;
  display: grid;
}

.message {
  border: 1px solid var(--text);
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.message-header {
  flex-direction: row;
  align-items: center;
  gap: .3em;
  display: flex;
}

.timestamp {
  flex-wrap: wrap;
  gap: .3em;
  display: flex;
}

.date, .time, .other-user-name {
  word-break: keep-all;
  text-wrap: nowrap;
  overflow-wrap: normal;
}

.text {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
}

.chat-header {
  background-color: var(--active-menu-item-background);
  gap: 10px;
  width: 100%;
  padding: 10px;
  font-size: larger;
  font-weight: bold;
  display: flex;
}

.chat-list {
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.chat-time {
  flex-wrap: wrap;
  align-self: center;
  gap: .3em;
  font-size: small;
  display: flex;
}

.chat-in, .chat-out {
  white-space: pre-line;
  overflow-wrap: break-word;
  border-radius: 10px;
  max-width: 80%;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 5px;
}

.chat-in {
  background-color: var(--panel);
  align-self: flex-start;
}

.chat-out {
  background-color: var(--blue-background);
  align-self: flex-end;
}

.chat-input {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.chat-input textarea {
  font: inherit;
  width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.medium {
  max-width: 800px;
}

textarea.bio {
  font: inherit;
  width: 100%;
  height: auto;
}

.top-margin-0 {
  margin-top: 0;
}
/*# sourceMappingURL=400.f02ed2b6.css.map */
